*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#root{
    min-height: 100vh;
    
    --navbar-height: 6rem;
    --footer-height: 6rem;
    --footer-padding: 1rem;
    --orange: #FF8C00;
    --purple: #7528FF;

    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: clip;
    background-image: url(../img/protoTexturesPng/Dark/texture_08.png);
    background-size: 30%;
}

/*#region Reset */


body {
    margin: 0;
    background-color: #252525;
}
a {
    text-decoration: none;
    color: inherit;
}
h1,h2,h3,h4 {
    margin: 0;
    padding: 0;
}
ul {
    list-style: none;
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}
p {
    margin: 0;
    padding: 0;
}


/*#endregion */

/*#region Common */

.highlighted{
    color: var(--purple);
    font-weight: 700;
}
.highlighted::selection{
    color: var(--purple);
}
::selection {
    color: var(--orange);
    background: black;
}

.block{
    background-color: var(--orange);

    padding: 1.5rem;
    text-align: center;
    
    --shadow-offset: clamp(0.3rem, 0.5541vw, 1.5rem);
    
    box-shadow: black var(--shadow-offset) var(--shadow-offset);
}

.section__header{
    font-family: "JetBrains Mono", serif;
    font-weight: 900;
    font-size: clamp(2rem, 1rem + 3.7vw, 6rem);
    color: white;
    
    background-color: var(--purple);
    margin-bottom: 2rem;
    
    user-select: none;
}

.section{
    padding-top: calc(var(--navbar-height) + 2rem);
    height: fit-content;
    min-height: 80vh;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100vw;
    gap: 2rem;
}

@media screen and (orientation: portrait)  {
    .section{
        min-height: 50vh;
    }
}

/*#endregion */

/*#region Fonts Assign*/

.navigation-bar {
    font-family: 'JetBrains Mono', sans-serif;
}

.header {
    font-family: 'JetBrains Mono', sans-serif;
    font-weight: 900;
}

.footer {
    font-family: 'JetBrains Mono', sans-serif;
}

.about {
    font-family: 'JetBrains Mono', sans-serif;
    position: relative;
}

.project-wrapper {
    font-family: 'JetBrains Mono', sans-serif;
}

.block {
    font-family: 'JetBrains Mono', sans-serif;
}

/*#endregion*/

/*#region Font Declaration & Resizing*/

@font-face {
    font-family: 'JetBrains Mono';
    font-weight: 400;
    font-style: normal;
    src: url("/src/fonts/rider fonts/JetBrainsMono-Regular.woff2") format('woff2');
}
@font-face {
    font-family: 'JetBrains Mono';
    font-weight: 700;
    font-style: normal;
    src: url("/src/fonts/rider fonts/JetBrainsMono-Bold.woff2") format('woff2');
}
@font-face {
    font-family: 'JetBrains Mono';
    font-weight: 900;
    font-style: normal;
    src: url("/src/fonts/rider fonts/JetBrainsMono-ExtraBold.woff2") format('woff2');
}

html {
    font-size: 62.5%;
}

@media (max-width: 1500px) {
    html {
        font-size: 57.25%;
    }
}

@media (max-width: 1200px) {
    html {
        font-size: 50%;
    }
}

@media (max-width: 770px) {
    html {
        font-size: 43.75%;
    }
}

@media (max-width: 615px) {
    html {
        font-size: 40%;
    }
}

/*#endregion*/

/*#region arrow */

.arrow__container {
    display: flex;
    cursor: pointer;
}

.arrow__container .arrows {
    position: absolute;
    top: 80%;
    left: 50%;
    width: 80px;
    height: 80px;
    transform: translate(-50%, -50%);
}
.arrow__container .arrows:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 26.6666666667px solid rgba(237, 236, 236, 0.7);
    border-bottom: 26.6666666667px solid rgba(237, 236, 236, 0.7);
    transform: translate(26.6666666667px, 106.6666666667px) rotate(-45deg);
    animation: arrows 3s linear infinite;
}
.arrow__container .arrows:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 26.6666666667px solid rgba(237, 236, 236, 0.5);
    border-bottom: 26.6666666667px solid rgba(237, 236, 236, 0.5);
    transform: translate(53.3333333333px, 0px) rotate(-45deg);
    animation: arrows 3s linear infinite -1.5s;
}

@keyframes arrows {
    0% {
        border-left: 26.6666666667px solid rgba(0, 0, 0, 0);
        border-bottom: 26.6666666667px solid rgba(0, 0, 0, 0);
        transform: translate(-13.3333333333px, -53.3333333333px) rotate(-45deg);
    }
    10%, 90% {
        border-left: 26.6666666667px solid rgba(0, 0, 0, 0);
        border-bottom: 26.6666666667px solid rgba(0, 0, 0, 0);
    }
    50% {
        border-left: 26.6666666667px solid rgba(237, 236, 236, 0.5);
        border-bottom: 26.6666666667px solid rgba(237, 236, 236, 0.5);
        transform: translate(-13.3333333333px, 0px) rotate(-45deg);
    }
    100% {
        border-left: 26.6666666667px solid rgba(0, 0, 0, 0);
        border-bottom: 26.6666666667px solid rgba(0, 0, 0, 0);
        transform: translate(-13.3333333333px, 53.3333333333px) rotate(-45deg);
    }
}
/*#endregion */