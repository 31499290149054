.project__back-button{
    position: fixed;
    
    left: 5rem;
    top: 5rem;
    
    width: 10rem;
    height: 4rem;
    
    padding: 0;
    
    border: none;

    z-index: 1000;

    background-color: var(--orange);
    text-align: center;
    font-weight: 900;
    color: black;
    font-size: 1.5rem;
    
    transition: transform 0.1s ease-in-out;
}
.project__back-button:hover{
    transform: scale(90%);
}

:root{
    --project-gap: 3rem;
}


/*BODY*/
.project {
    min-height: calc(100vh - var(--footer-height) - (2 * var(--footer-padding)) - 6rem);
    height: fit-content;
    margin: 3rem 15%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    gap: 2rem;
}

.project__info{
    font-size: 1.4rem;
}

.project__info a{
    color: var(--purple);
    font-weight: 700;
    text-decoration: underline;
}

.project__info a:hover{
    color: #9e69ff;
}

/*IMAGE*/
.project__images {
    gap: 5rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.project__images .project-card{
    height: 30rem;
}

/*LAST ROW*/
.project__last-row{
    width: 100%;
    
    height: auto;

    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    
    gap: 2rem;
}

.project__last-row h2{
    text-align: center;

    font-weight: 900;
    user-select: none;
    
    font-size: 2rem;
}

.project__last-row hr{
    border: 1px solid black;
    margin-bottom: 1rem;
}


.project__details {
    width: 100%;
    
    height: auto;
    flex-grow: 1;

    text-align: left;

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(9, auto);
    
    font-size: clamp(1.2rem, 0.2rem + 0.7vw, 3rem);
}

.dotted {
    list-style: inside;
}

.project__details hr{
    border: 1px solid black;
    margin-bottom: 1rem;
}


.project__problems {
    background-color: var(--purple);
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 1rem;
    color: white;
}

.project__problems hr{
    border: 1px solid white;
    margin: 0 1rem 1rem 1rem;
}

.project__problems p{
    text-align: left;
    margin:0 1rem;
}


.project__buttons{
    width: 30%;
}



@media screen and (orientation: portrait) {
    .project__images {
        gap: 2rem;
        flex-direction: column;
    }

    .project__images .project-card{
        width: 70%;
    }

    .project__last-row{
        grid-template-columns: 1fr;
    }

    .project__back-button{

        left: 0.5rem;
        top: 0.5rem;

    }
    
    .project__details {
        grid-template-rows: repeat(4, auto);

    }
    
    .project__buttons{
        width: 70%;
    }
}