.header {
    height: fit-content;
    min-height: 100vh;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__info {
    height: 100%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    gap: clamp(1rem, 1.6vw, 5rem);
}
.header__name {
    text-align: center;
    font-size: clamp(5rem, 6vw, 10rem);
    padding: 0.7rem;
    line-height: 100%;
    width: max-content;
    color: black;
    user-select: none;

}
.header__role {
    color: white;
    font-size: clamp(3rem, 3vw, 8rem);
    
    user-select: none;

    background-color: var(--purple);
    padding: 0.7rem;
    line-height: 100%;
    width: max-content;
}