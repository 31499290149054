.navigation-bar {
    background-color: #252525;
    height: var(--navbar-height);
    
    position: sticky;
    top: 0;
    z-index: 99999999999;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    border-bottom: 1px solid #1a1a1a;
    backdrop-filter: blur(8px);
    box-shadow: 0 4px 4px rgba(0,0,0,0.3);
}

.navigation{
    display: flex;
    gap: 3rem;
    margin-right: 5rem;
    
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    
    user-select: none;
}

.navigation__item {
    transition: all 0.2s;
    cursor: pointer;
}

.navigation__item:hover {
    color: var(--orange);
}

.navigation__item--active {
    color: var(--orange);
    font-weight: 700;
}

@media (max-width: 1200px) {
    .navigation {
        margin-right: 3rem;
    }
}

@media (max-width: 700px) {
    .navigation {
        margin-right: 0;
    }

    .navigation-bar {
        justify-content: center;
    }
}