.about__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(0.3rem, 1.25vw, 3rem);
    width: 35%;
}
.about__text {
    font-family: "JetBrains Mono", monospace;
    color: black;
    font-size: clamp(1.5rem, 1.3vw, 4rem);
}

.about__download{    
    background-color: black;
    background-size: 30%;
    
    color: var(--orange);
    
    padding: 10px;
    font-size: clamp(1rem, 0.5rem + 1.3vw, 4rem);
    font-family: "JetBrains Mono", serif;
    font-weight: 700;
    
    transition: all 0.2s ease-in-out;
}
.about__download:hover{
    transform: scale(90%);
}

.about__contacts{
    width: auto;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    
    background-color: var(--purple);
}
.about__contacts a img{
    filter: invert(94%) sepia(0%) saturate(25%) hue-rotate(81deg) brightness(106%) contrast(105%);
}
.about__contacts a img:hover{
    filter: invert(52%) sepia(45%) saturate(1369%) hue-rotate(360deg) brightness(103%) contrast(105%);
}


@media screen and (orientation: portrait)  {
    .about__info{
        width: 60%;
    }
}