.project-card {
    background-image: url(../../img/protoTexturesPng/Orange/texture_09.png);
    background-size: 20%;
    
    aspect-ratio: 4/3;
    
    padding: 0;
}

.project-card--hover_enabled{
    background-image: url(../../img/protoTexturesPng/Orange/texture_09.png);
    background-size: 20%;

    aspect-ratio: 4/3;

    padding: 0;

    position: relative;
    
    transition: transform 0.1s ease-in-out;
}

.project-card__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    margin: var(--card-padding);
    
    user-select: none;
    height: calc(100% - (var(--card-padding) * 2));
    width: calc(100% - (var(--card-padding) * 2));
    
    overflow: hidden;
}

:root{
    --card-padding: 0.5rem;
    --project-card-opacity: 0;
    --a: 1 - var(--project-card-opacity);
}

.project-card__title {
    position: absolute;
    opacity: var(--project-card-opacity);
    transition: opacity 0.2s ease-in-out;
    
    padding: 5rem;
    text-align: center;
    
    word-break: normal;
    font-family: 'JetBrains Mono', sans-serif;
    font-size: clamp(2rem, 1rem + 0.7005vw, 2.5rem);
    color: black;
}
.project-card__title h2{
    font-size: clamp(3rem, 1.5rem + 0.7005vw, 4.5rem);
}
.project-card__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    
    opacity: max(var(--a), -1*var(--a));
    transition: opacity 0.3s ease;
}

.project-card--hover_enabled:hover{
    transform: scale(95%);
}

.project-card--hover_enabled:hover .project-card__img {
    opacity: var(--project-card-opacity);
}
.project-card--hover_enabled:hover .project-card__title {
    opacity: max(var(--a), -1*var(--a));
}


.project-card__icon{
    border: white 3px solid;
    position: absolute;
    left: 1rem;
    top: 1rem;
    width: 7%;
    aspect-ratio: 1;

    transition: opacity 0.5s ease;
    filter: brightness(0) saturate(100%) invert(97%) sepia(7%) saturate(453%) hue-rotate(221deg) brightness(122%) contrast(100%);
}


@media screen and (orientation: portrait) {
    .project-card__title {
        opacity: 1;
        color: white;
        text-shadow: black 2px 2px;
    }

    .project-card--hover_enabled:hover .project-card__title {
        opacity: 1;
    }

    .project-card--hover_enabled:hover .project-card__img {
        opacity: 1;
    }
}