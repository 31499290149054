:root{
    --down-button__color: #FF8C00;
}

.down-button{
    aspect-ratio: 1;
    height: clamp(4rem, 3rem + 3.7vw, 12rem);
    
    
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    user-select: none;
}
.down-button__body{
    aspect-ratio: 1;
    height: 50%; 
    
    background: var(--down-button__color);
    z-index: 1;

    transition: all 0.4s ease-in-out;
    
}

.down{
    bottom: 1.5rem;
    scale: 1 -1 -1;
}
.up{
    top: 1.5rem;
    scale: 1 1 1;
}


/* Custom Animate.css */


.down-button__body img{
    place-self: center;
    aspect-ratio: 1;
    height: 50%;
    margin: 25%;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: upDown;
    animation-timing-function: ease-in-out;
}
@keyframes upDown {
    0% {
        translate: 0 10%;
    }

    50% {

        translate: 0 -40%;
        
    }

    100% {
        translate: 0 10%;
    }
}

.down-button__effect{
    width: 80%;
    aspect-ratio: 1;    
    
    background: var(--down-button__color);
    opacity: 0.5;
    position: absolute;

    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-name: zoomIn;
    animation-timing-function: ease-in-out;    
}
@keyframes zoomIn {
    0% {
        opacity: 0;
        transform: scale3d(.4, .4, .4);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
}

.down-button__body:hover{
    scale: 1.1;
}