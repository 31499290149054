.footer {
	height: var(--footer-height);
	padding: var(--footer-padding) 0 var(--footer-padding);
	
	background-image: url("../../img/protoTexturesPng/Light/texture_07.png");
	background-size: 5%;
    color: aliceblue;


	display: flex;
	flex-direction: column;
	align-items: center;
}

.footer_socials {
	display: flex;
	column-gap: 1rem;
	align-items: center;
}
.footer__icon{
	user-select: none;
	
	width: clamp(2rem, 1.7294rem + 1.1544vw, 4.5rem);
	
	transition: scale 0.3s ease-in-out;
}
.footer__icon:hover{	
	filter: invert(48%) sepia(88%) saturate(1018%) hue-rotate(1deg) brightness(105%) contrast(103%);
	scale: 1.1;
}

.footer__email {
	font-size: clamp(0.9rem, 0.8242rem + 0.3232vw, 1.6rem);
	font-family: "JetBrains Mono", sans-serif;
	color: black;
	font-weight: 700;
}
.footer__email:hover{
	filter: invert(48%) sepia(88%) saturate(1018%) hue-rotate(1deg) brightness(105%) contrast(103%);
	
	font-weight: 900;
}